@font-face {
	font-family: 'themify';
	src:url(2c454669bdf3aebf32a1bd8ac1e0d2d6.eot);
	src:url(2c454669bdf3aebf32a1bd8ac1e0d2d6.eot?#iefix-fvbane) format('embedded-opentype'),
		url(a1ecc3b826d01251edddf29c3e4e1e97.woff) format('woff'),
		url(e23a7dcaefbde4e74e263247aa42ecd7.ttf) format('truetype'),
		url(ea3d91d40fa0cf9f85a3f282c93c7a66.svg#themify) format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="ti-"], [class*=" ti-"] {
	font-family: 'themify';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ti-wand:before {
	content: "\E600";
}
.ti-volume:before {
	content: "\E601";
}
.ti-user:before {
	content: "\E602";
}
.ti-unlock:before {
	content: "\E603";
}
.ti-unlink:before {
	content: "\E604";
}
.ti-trash:before {
	content: "\E605";
}
.ti-thought:before {
	content: "\E606";
}
.ti-target:before {
	content: "\E607";
}
.ti-tag:before {
	content: "\E608";
}
.ti-tablet:before {
	content: "\E609";
}
.ti-star:before {
	content: "\E60A";
}
.ti-spray:before {
	content: "\E60B";
}
.ti-signal:before {
	content: "\E60C";
}
.ti-shopping-cart:before {
	content: "\E60D";
}
.ti-shopping-cart-full:before {
	content: "\E60E";
}
.ti-settings:before {
	content: "\E60F";
}
.ti-search:before {
	content: "\E610";
}
.ti-zoom-in:before {
	content: "\E611";
}
.ti-zoom-out:before {
	content: "\E612";
}
.ti-cut:before {
	content: "\E613";
}
.ti-ruler:before {
	content: "\E614";
}
.ti-ruler-pencil:before {
	content: "\E615";
}
.ti-ruler-alt:before {
	content: "\E616";
}
.ti-bookmark:before {
	content: "\E617";
}
.ti-bookmark-alt:before {
	content: "\E618";
}
.ti-reload:before {
	content: "\E619";
}
.ti-plus:before {
	content: "\E61A";
}
.ti-pin:before {
	content: "\E61B";
}
.ti-pencil:before {
	content: "\E61C";
}
.ti-pencil-alt:before {
	content: "\E61D";
}
.ti-paint-roller:before {
	content: "\E61E";
}
.ti-paint-bucket:before {
	content: "\E61F";
}
.ti-na:before {
	content: "\E620";
}
.ti-mobile:before {
	content: "\E621";
}
.ti-minus:before {
	content: "\E622";
}
.ti-medall:before {
	content: "\E623";
}
.ti-medall-alt:before {
	content: "\E624";
}
.ti-marker:before {
	content: "\E625";
}
.ti-marker-alt:before {
	content: "\E626";
}
.ti-arrow-up:before {
	content: "\E627";
}
.ti-arrow-right:before {
	content: "\E628";
}
.ti-arrow-left:before {
	content: "\E629";
}
.ti-arrow-down:before {
	content: "\E62A";
}
.ti-lock:before {
	content: "\E62B";
}
.ti-location-arrow:before {
	content: "\E62C";
}
.ti-link:before {
	content: "\E62D";
}
.ti-layout:before {
	content: "\E62E";
}
.ti-layers:before {
	content: "\E62F";
}
.ti-layers-alt:before {
	content: "\E630";
}
.ti-key:before {
	content: "\E631";
}
.ti-import:before {
	content: "\E632";
}
.ti-image:before {
	content: "\E633";
}
.ti-heart:before {
	content: "\E634";
}
.ti-heart-broken:before {
	content: "\E635";
}
.ti-hand-stop:before {
	content: "\E636";
}
.ti-hand-open:before {
	content: "\E637";
}
.ti-hand-drag:before {
	content: "\E638";
}
.ti-folder:before {
	content: "\E639";
}
.ti-flag:before {
	content: "\E63A";
}
.ti-flag-alt:before {
	content: "\E63B";
}
.ti-flag-alt-2:before {
	content: "\E63C";
}
.ti-eye:before {
	content: "\E63D";
}
.ti-export:before {
	content: "\E63E";
}
.ti-exchange-vertical:before {
	content: "\E63F";
}
.ti-desktop:before {
	content: "\E640";
}
.ti-cup:before {
	content: "\E641";
}
.ti-crown:before {
	content: "\E642";
}
.ti-comments:before {
	content: "\E643";
}
.ti-comment:before {
	content: "\E644";
}
.ti-comment-alt:before {
	content: "\E645";
}
.ti-close:before {
	content: "\E646";
}
.ti-clip:before {
	content: "\E647";
}
.ti-angle-up:before {
	content: "\E648";
}
.ti-angle-right:before {
	content: "\E649";
}
.ti-angle-left:before {
	content: "\E64A";
}
.ti-angle-down:before {
	content: "\E64B";
}
.ti-check:before {
	content: "\E64C";
}
.ti-check-box:before {
	content: "\E64D";
}
.ti-camera:before {
	content: "\E64E";
}
.ti-announcement:before {
	content: "\E64F";
}
.ti-brush:before {
	content: "\E650";
}
.ti-briefcase:before {
	content: "\E651";
}
.ti-bolt:before {
	content: "\E652";
}
.ti-bolt-alt:before {
	content: "\E653";
}
.ti-blackboard:before {
	content: "\E654";
}
.ti-bag:before {
	content: "\E655";
}
.ti-move:before {
	content: "\E656";
}
.ti-arrows-vertical:before {
	content: "\E657";
}
.ti-arrows-horizontal:before {
	content: "\E658";
}
.ti-fullscreen:before {
	content: "\E659";
}
.ti-arrow-top-right:before {
	content: "\E65A";
}
.ti-arrow-top-left:before {
	content: "\E65B";
}
.ti-arrow-circle-up:before {
	content: "\E65C";
}
.ti-arrow-circle-right:before {
	content: "\E65D";
}
.ti-arrow-circle-left:before {
	content: "\E65E";
}
.ti-arrow-circle-down:before {
	content: "\E65F";
}
.ti-angle-double-up:before {
	content: "\E660";
}
.ti-angle-double-right:before {
	content: "\E661";
}
.ti-angle-double-left:before {
	content: "\E662";
}
.ti-angle-double-down:before {
	content: "\E663";
}
.ti-zip:before {
	content: "\E664";
}
.ti-world:before {
	content: "\E665";
}
.ti-wheelchair:before {
	content: "\E666";
}
.ti-view-list:before {
	content: "\E667";
}
.ti-view-list-alt:before {
	content: "\E668";
}
.ti-view-grid:before {
	content: "\E669";
}
.ti-uppercase:before {
	content: "\E66A";
}
.ti-upload:before {
	content: "\E66B";
}
.ti-underline:before {
	content: "\E66C";
}
.ti-truck:before {
	content: "\E66D";
}
.ti-timer:before {
	content: "\E66E";
}
.ti-ticket:before {
	content: "\E66F";
}
.ti-thumb-up:before {
	content: "\E670";
}
.ti-thumb-down:before {
	content: "\E671";
}
.ti-text:before {
	content: "\E672";
}
.ti-stats-up:before {
	content: "\E673";
}
.ti-stats-down:before {
	content: "\E674";
}
.ti-split-v:before {
	content: "\E675";
}
.ti-split-h:before {
	content: "\E676";
}
.ti-smallcap:before {
	content: "\E677";
}
.ti-shine:before {
	content: "\E678";
}
.ti-shift-right:before {
	content: "\E679";
}
.ti-shift-left:before {
	content: "\E67A";
}
.ti-shield:before {
	content: "\E67B";
}
.ti-notepad:before {
	content: "\E67C";
}
.ti-server:before {
	content: "\E67D";
}
.ti-quote-right:before {
	content: "\E67E";
}
.ti-quote-left:before {
	content: "\E67F";
}
.ti-pulse:before {
	content: "\E680";
}
.ti-printer:before {
	content: "\E681";
}
.ti-power-off:before {
	content: "\E682";
}
.ti-plug:before {
	content: "\E683";
}
.ti-pie-chart:before {
	content: "\E684";
}
.ti-paragraph:before {
	content: "\E685";
}
.ti-panel:before {
	content: "\E686";
}
.ti-package:before {
	content: "\E687";
}
.ti-music:before {
	content: "\E688";
}
.ti-music-alt:before {
	content: "\E689";
}
.ti-mouse:before {
	content: "\E68A";
}
.ti-mouse-alt:before {
	content: "\E68B";
}
.ti-money:before {
	content: "\E68C";
}
.ti-microphone:before {
	content: "\E68D";
}
.ti-menu:before {
	content: "\E68E";
}
.ti-menu-alt:before {
	content: "\E68F";
}
.ti-map:before {
	content: "\E690";
}
.ti-map-alt:before {
	content: "\E691";
}
.ti-loop:before {
	content: "\E692";
}
.ti-location-pin:before {
	content: "\E693";
}
.ti-list:before {
	content: "\E694";
}
.ti-light-bulb:before {
	content: "\E695";
}
.ti-Italic:before {
	content: "\E696";
}
.ti-info:before {
	content: "\E697";
}
.ti-infinite:before {
	content: "\E698";
}
.ti-id-badge:before {
	content: "\E699";
}
.ti-hummer:before {
	content: "\E69A";
}
.ti-home:before {
	content: "\E69B";
}
.ti-help:before {
	content: "\E69C";
}
.ti-headphone:before {
	content: "\E69D";
}
.ti-harddrives:before {
	content: "\E69E";
}
.ti-harddrive:before {
	content: "\E69F";
}
.ti-gift:before {
	content: "\E6A0";
}
.ti-game:before {
	content: "\E6A1";
}
.ti-filter:before {
	content: "\E6A2";
}
.ti-files:before {
	content: "\E6A3";
}
.ti-file:before {
	content: "\E6A4";
}
.ti-eraser:before {
	content: "\E6A5";
}
.ti-envelope:before {
	content: "\E6A6";
}
.ti-download:before {
	content: "\E6A7";
}
.ti-direction:before {
	content: "\E6A8";
}
.ti-direction-alt:before {
	content: "\E6A9";
}
.ti-dashboard:before {
	content: "\E6AA";
}
.ti-control-stop:before {
	content: "\E6AB";
}
.ti-control-shuffle:before {
	content: "\E6AC";
}
.ti-control-play:before {
	content: "\E6AD";
}
.ti-control-pause:before {
	content: "\E6AE";
}
.ti-control-forward:before {
	content: "\E6AF";
}
.ti-control-backward:before {
	content: "\E6B0";
}
.ti-cloud:before {
	content: "\E6B1";
}
.ti-cloud-up:before {
	content: "\E6B2";
}
.ti-cloud-down:before {
	content: "\E6B3";
}
.ti-clipboard:before {
	content: "\E6B4";
}
.ti-car:before {
	content: "\E6B5";
}
.ti-calendar:before {
	content: "\E6B6";
}
.ti-book:before {
	content: "\E6B7";
}
.ti-bell:before {
	content: "\E6B8";
}
.ti-basketball:before {
	content: "\E6B9";
}
.ti-bar-chart:before {
	content: "\E6BA";
}
.ti-bar-chart-alt:before {
	content: "\E6BB";
}
.ti-back-right:before {
	content: "\E6BC";
}
.ti-back-left:before {
	content: "\E6BD";
}
.ti-arrows-corner:before {
	content: "\E6BE";
}
.ti-archive:before {
	content: "\E6BF";
}
.ti-anchor:before {
	content: "\E6C0";
}
.ti-align-right:before {
	content: "\E6C1";
}
.ti-align-left:before {
	content: "\E6C2";
}
.ti-align-justify:before {
	content: "\E6C3";
}
.ti-align-center:before {
	content: "\E6C4";
}
.ti-alert:before {
	content: "\E6C5";
}
.ti-alarm-clock:before {
	content: "\E6C6";
}
.ti-agenda:before {
	content: "\E6C7";
}
.ti-write:before {
	content: "\E6C8";
}
.ti-window:before {
	content: "\E6C9";
}
.ti-widgetized:before {
	content: "\E6CA";
}
.ti-widget:before {
	content: "\E6CB";
}
.ti-widget-alt:before {
	content: "\E6CC";
}
.ti-wallet:before {
	content: "\E6CD";
}
.ti-video-clapper:before {
	content: "\E6CE";
}
.ti-video-camera:before {
	content: "\E6CF";
}
.ti-vector:before {
	content: "\E6D0";
}
.ti-themify-logo:before {
	content: "\E6D1";
}
.ti-themify-favicon:before {
	content: "\E6D2";
}
.ti-themify-favicon-alt:before {
	content: "\E6D3";
}
.ti-support:before {
	content: "\E6D4";
}
.ti-stamp:before {
	content: "\E6D5";
}
.ti-split-v-alt:before {
	content: "\E6D6";
}
.ti-slice:before {
	content: "\E6D7";
}
.ti-shortcode:before {
	content: "\E6D8";
}
.ti-shift-right-alt:before {
	content: "\E6D9";
}
.ti-shift-left-alt:before {
	content: "\E6DA";
}
.ti-ruler-alt-2:before {
	content: "\E6DB";
}
.ti-receipt:before {
	content: "\E6DC";
}
.ti-pin2:before {
	content: "\E6DD";
}
.ti-pin-alt:before {
	content: "\E6DE";
}
.ti-pencil-alt2:before {
	content: "\E6DF";
}
.ti-palette:before {
	content: "\E6E0";
}
.ti-more:before {
	content: "\E6E1";
}
.ti-more-alt:before {
	content: "\E6E2";
}
.ti-microphone-alt:before {
	content: "\E6E3";
}
.ti-magnet:before {
	content: "\E6E4";
}
.ti-line-double:before {
	content: "\E6E5";
}
.ti-line-dotted:before {
	content: "\E6E6";
}
.ti-line-dashed:before {
	content: "\E6E7";
}
.ti-layout-width-full:before {
	content: "\E6E8";
}
.ti-layout-width-default:before {
	content: "\E6E9";
}
.ti-layout-width-default-alt:before {
	content: "\E6EA";
}
.ti-layout-tab:before {
	content: "\E6EB";
}
.ti-layout-tab-window:before {
	content: "\E6EC";
}
.ti-layout-tab-v:before {
	content: "\E6ED";
}
.ti-layout-tab-min:before {
	content: "\E6EE";
}
.ti-layout-slider:before {
	content: "\E6EF";
}
.ti-layout-slider-alt:before {
	content: "\E6F0";
}
.ti-layout-sidebar-right:before {
	content: "\E6F1";
}
.ti-layout-sidebar-none:before {
	content: "\E6F2";
}
.ti-layout-sidebar-left:before {
	content: "\E6F3";
}
.ti-layout-placeholder:before {
	content: "\E6F4";
}
.ti-layout-menu:before {
	content: "\E6F5";
}
.ti-layout-menu-v:before {
	content: "\E6F6";
}
.ti-layout-menu-separated:before {
	content: "\E6F7";
}
.ti-layout-menu-full:before {
	content: "\E6F8";
}
.ti-layout-media-right-alt:before {
	content: "\E6F9";
}
.ti-layout-media-right:before {
	content: "\E6FA";
}
.ti-layout-media-overlay:before {
	content: "\E6FB";
}
.ti-layout-media-overlay-alt:before {
	content: "\E6FC";
}
.ti-layout-media-overlay-alt-2:before {
	content: "\E6FD";
}
.ti-layout-media-left-alt:before {
	content: "\E6FE";
}
.ti-layout-media-left:before {
	content: "\E6FF";
}
.ti-layout-media-center-alt:before {
	content: "\E700";
}
.ti-layout-media-center:before {
	content: "\E701";
}
.ti-layout-list-thumb:before {
	content: "\E702";
}
.ti-layout-list-thumb-alt:before {
	content: "\E703";
}
.ti-layout-list-post:before {
	content: "\E704";
}
.ti-layout-list-large-image:before {
	content: "\E705";
}
.ti-layout-line-solid:before {
	content: "\E706";
}
.ti-layout-grid4:before {
	content: "\E707";
}
.ti-layout-grid3:before {
	content: "\E708";
}
.ti-layout-grid2:before {
	content: "\E709";
}
.ti-layout-grid2-thumb:before {
	content: "\E70A";
}
.ti-layout-cta-right:before {
	content: "\E70B";
}
.ti-layout-cta-left:before {
	content: "\E70C";
}
.ti-layout-cta-center:before {
	content: "\E70D";
}
.ti-layout-cta-btn-right:before {
	content: "\E70E";
}
.ti-layout-cta-btn-left:before {
	content: "\E70F";
}
.ti-layout-column4:before {
	content: "\E710";
}
.ti-layout-column3:before {
	content: "\E711";
}
.ti-layout-column2:before {
	content: "\E712";
}
.ti-layout-accordion-separated:before {
	content: "\E713";
}
.ti-layout-accordion-merged:before {
	content: "\E714";
}
.ti-layout-accordion-list:before {
	content: "\E715";
}
.ti-ink-pen:before {
	content: "\E716";
}
.ti-info-alt:before {
	content: "\E717";
}
.ti-help-alt:before {
	content: "\E718";
}
.ti-headphone-alt:before {
	content: "\E719";
}
.ti-hand-point-up:before {
	content: "\E71A";
}
.ti-hand-point-right:before {
	content: "\E71B";
}
.ti-hand-point-left:before {
	content: "\E71C";
}
.ti-hand-point-down:before {
	content: "\E71D";
}
.ti-gallery:before {
	content: "\E71E";
}
.ti-face-smile:before {
	content: "\E71F";
}
.ti-face-sad:before {
	content: "\E720";
}
.ti-credit-card:before {
	content: "\E721";
}
.ti-control-skip-forward:before {
	content: "\E722";
}
.ti-control-skip-backward:before {
	content: "\E723";
}
.ti-control-record:before {
	content: "\E724";
}
.ti-control-eject:before {
	content: "\E725";
}
.ti-comments-smiley:before {
	content: "\E726";
}
.ti-brush-alt:before {
	content: "\E727";
}
.ti-youtube:before {
	content: "\E728";
}
.ti-vimeo:before {
	content: "\E729";
}
.ti-twitter:before {
	content: "\E72A";
}
.ti-time:before {
	content: "\E72B";
}
.ti-tumblr:before {
	content: "\E72C";
}
.ti-skype:before {
	content: "\E72D";
}
.ti-share:before {
	content: "\E72E";
}
.ti-share-alt:before {
	content: "\E72F";
}
.ti-rocket:before {
	content: "\E730";
}
.ti-pinterest:before {
	content: "\E731";
}
.ti-new-window:before {
	content: "\E732";
}
.ti-microsoft:before {
	content: "\E733";
}
.ti-list-ol:before {
	content: "\E734";
}
.ti-linkedin:before {
	content: "\E735";
}
.ti-layout-sidebar-2:before {
	content: "\E736";
}
.ti-layout-grid4-alt:before {
	content: "\E737";
}
.ti-layout-grid3-alt:before {
	content: "\E738";
}
.ti-layout-grid2-alt:before {
	content: "\E739";
}
.ti-layout-column4-alt:before {
	content: "\E73A";
}
.ti-layout-column3-alt:before {
	content: "\E73B";
}
.ti-layout-column2-alt:before {
	content: "\E73C";
}
.ti-instagram:before {
	content: "\E73D";
}
.ti-google:before {
	content: "\E73E";
}
.ti-github:before {
	content: "\E73F";
}
.ti-flickr:before {
	content: "\E740";
}
.ti-facebook:before {
	content: "\E741";
}
.ti-dropbox:before {
	content: "\E742";
}
.ti-dribbble:before {
	content: "\E743";
}
.ti-apple:before {
	content: "\E744";
}
.ti-android:before {
	content: "\E745";
}
.ti-save:before {
	content: "\E746";
}
.ti-save-alt:before {
	content: "\E747";
}
.ti-yahoo:before {
	content: "\E748";
}
.ti-wordpress:before {
	content: "\E749";
}
.ti-vimeo-alt:before {
	content: "\E74A";
}
.ti-twitter-alt:before {
	content: "\E74B";
}
.ti-tumblr-alt:before {
	content: "\E74C";
}
.ti-trello:before {
	content: "\E74D";
}
.ti-stack-overflow:before {
	content: "\E74E";
}
.ti-soundcloud:before {
	content: "\E74F";
}
.ti-sharethis:before {
	content: "\E750";
}
.ti-sharethis-alt:before {
	content: "\E751";
}
.ti-reddit:before {
	content: "\E752";
}
.ti-pinterest-alt:before {
	content: "\E753";
}
.ti-microsoft-alt:before {
	content: "\E754";
}
.ti-linux:before {
	content: "\E755";
}
.ti-jsfiddle:before {
	content: "\E756";
}
.ti-joomla:before {
	content: "\E757";
}
.ti-html5:before {
	content: "\E758";
}
.ti-flickr-alt:before {
	content: "\E759";
}
.ti-email:before {
	content: "\E75A";
}
.ti-drupal:before {
	content: "\E75B";
}
.ti-dropbox-alt:before {
	content: "\E75C";
}
.ti-css3:before {
	content: "\E75D";
}
.ti-rss:before {
	content: "\E75E";
}
.ti-rss-alt:before {
	content: "\E75F";
}
